@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F7 !important;
}

#sideMenu::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#bottom:before {
  content: "";
  position:absolute;
  top:-40px;
  left:0;
  width: 100vw;
  height: 0;
  border-left: calc(50vw) solid transparent;
  border-right: calc(50vw) solid transparent;
  border-bottom: 40px solid #19323c;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.grecaptcha-badge {
  visibility: hidden;
}

.bullets-left {
  text-align: right;
  list-style-type: disc;
  direction:rtl;
  margin: 0 1em;
}

/* CHAT BOT STYLES */
.chatbot-conversation-container {
  height: 67vh;
  min-height: 250px;
  margin: 1em 0;
}
#chatbot-conversation {
  overflow-y: scroll;
}

/* stop ugly scroll bar on some browsers */
.chatbot-conversation-container::-webkit-scrollbar{
  display: none;
}

.speech {
  padding: 1em;
  margin: 1em auto;
  width: 100%;
  border-radius: 10px;
}

.speech:first-child {
  margin-top: 0;
}

.speech-ai {
  background: #253b4b;
  border-top-left-radius: 0;
  width: 70%;
  float: left}

.speech-human {
  background: #24976f;
  border-top-right-radius: 0;
  width: 70%;
  float: right;
}

.chatbot-input-container {
  display: flex;
}
